import request from 'utils/http-request';

export default async function NameToSMILES(name) {
  const smiles = await request(
    {
      url: `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/${name}/property/CanonicalSMILES/json`,
      method: 'GET',
    },
    false,
  );

  return smiles;
}
