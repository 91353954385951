import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams, Link } from 'react-router-dom';

import { useSearch } from 'hooks/query-hooks';
import ConvertCASRN from 'utils/casrn';

import Base64Image from 'components/base/Base64Image';
import Show from 'components/Show';
import Spinner from 'components/Spinner';

export default function PureConstantSearchCompounds() {
  const containerRef = useRef();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get('compound');
  const querySearchType = searchParams.get('type');
  const queryResult = useSearch(querySearch, querySearchType);

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [location]);

  return (
    <div className="content" ref={containerRef}>
      <Show show={!queryResult.isLoading && !queryResult.isError}>
        <h1 className="text-4xl font-semibold">Serach Result</h1>
        <ol className="mt-8 space-y-4">
          <Show show={queryResult.data?.response?.length === 0}>
            <div>No Data has been Found</div>
          </Show>
          {queryResult.data?.data.map(compound => (
            <Link
              className="flex cursor-pointer items-center space-x-4 border-2 p-2"
              to={`/search/pure-constant/${compound.id_compound}`}
              key={compound.id_compound}
            >
              <div className="aspect-square min-w-20 max-w-40 flex-auto border-2">
                {compound.image ? <Base64Image src={compound.image} /> : <div className="bg-gray w-full" />}
              </div>
              <div>
                <div className="mb-1 text-lg font-bold text-sky-800">{compound.compound_name}</div>
                <div>
                  <span className="font-semibold">CASRN: </span>
                  <span>{ConvertCASRN(compound.casrn)}</span>
                </div>
                <div>
                  <span className="font-semibold">Formula: </span>
                  <span>{compound.formula}</span>
                </div>
                <div>
                  <span className="font-semibold">InChI: </span>
                  <span>{compound.InChI}</span>
                </div>
                <div>
                  <span className="font-semibold">SMILES: </span>
                  <span>{compound.SMILES}</span>
                </div>
                <div>
                  <span className="font-semibold">Updated: </span>
                  <span>2024-08-11</span>
                </div>
              </div>
            </Link>
          ))}
        </ol>
      </Show>
      <Show show={queryResult.isLoading} as="div" className="flex h-full w-full items-center justify-center">
        <Spinner className="w-1/6" />
      </Show>
      <Show show={queryResult.isError} as="div" className="flex h-full flex-col items-center justify-center space-y-4">
        <div className="text-xl font-bold text-gray-500">에러가 발생했습니다.</div>
        <div className="text-xl font-bold text-gray-500"> 새로고침 혹은 뒤로가기를 해주십시요.</div>
      </Show>
    </div>
  );
}
