import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { treatData } from 'utils/pure_utils';
import { useEstimatePureConstants } from 'hooks/query-hooks';
import AsyncPage from 'components/AsyncPage';
import Base64Image from 'components/base/Base64Image';

import 'styles/pure_properties.css';

export default function PureConstantEstimationResult() {
  const [searchParams] = useSearchParams();
  const compound = searchParams.get('compound');
  const querySearchType = searchParams.get('type');
  const pureConstants = useEstimatePureConstants(compound, querySearchType);

  const [properties, setProperties] = useState({
    TBN: null,
    TMN: null,
    TC: null,
    PC: null,
    VC: null,
    TF: null,
    HFORM: null,
    HFUS: null,
  });

  useEffect(() => {
    if (pureConstants.isSuccess) {
      setProperties({
        TBN: { val: pureConstants.data.data.TBN[0], unc: pureConstants.data.data.TBN[1] },
        TMN: { val: pureConstants.data.data.TMN[0], unc: pureConstants.data.data.TMN[1] },
        TC: { val: pureConstants.data.data.TC[0], unc: pureConstants.data.data.TC[1] },
        PC: { val: pureConstants.data.data.PC[0], unc: pureConstants.data.data.PC[1] },
        VC: { val: pureConstants.data.data.VC[0], unc: pureConstants.data.data.VC[1] },
        TF: { val: pureConstants.data.data.TF[0], unc: pureConstants.data.data.TF[1] },
        HFORM: { val: pureConstants.data.data.HFORM[0], unc: pureConstants.data.data.HFORM[1] },
        HFUS: { val: pureConstants.data.data.HFUS[0], unc: pureConstants.data.data.HFUS[1] },
      });
    }
  }, [pureConstants.isSuccess]);

  return (
    <AsyncPage query={pureConstants} className="content">
      <div className="table-basic">
        <Base64Image
          className="mx-auto mb-8 aspect-square w-72 border-2 bg-gray-100"
          src={pureConstants.data?.data.compound_image}
        />
      </div>
      <h3 className="table-name">Phase Properties</h3>
      <div className="table-basic">
        <h4 className="table-index">Normal Boiling Temperature</h4>
        <p className="table-data">{treatData({ data: properties.TBN, round: 1, unit: 'K' })}</p>
        <h4 className="table-index">Melting Temperature</h4>
        <p className="table-data">{treatData({ data: properties.TMN, round: 1, unit: 'K' })}</p>
        <h4 className="table-index">Flash Point Temperature</h4>
        <p className="table-data">{treatData({ data: properties.TF, round: 1, unit: 'K' })}</p>
        <h4 className="table-index">Heat of Fusion</h4>
        <p className="table-data">{treatData({ data: properties.HFUS, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Critical Temperature</h4>
        <p className="table-data">{treatData({ data: properties.TC, round: 1, unit: 'K' })}</p>
        <h4 className="table-index">Critical Pressure</h4>
        <p className="table-data">{treatData({ data: properties.PC, round: 0, unit: 'kPa' })}</p>
        <h4 className="table-index">Critical Volume</h4>
        <p className="table-data">{treatData({ data: properties.VC, round: 2, unit: '㎤/mol' })}</p>
      </div>

      <h3 className="table-name">Ideal Gas Standard State Formation Energy</h3>
      <div className="table-basic">
        <h4 className="table-index">Enthalphy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.HFORM, round: 1, unit: 'kJ/mol' })}</p>
      </div>
    </AsyncPage>
  );
}
