function treatData({ data, round, unit }) {
  unit = unit ? unit : '';

  if (data) {
    if (data.unc) {
      return `${data.val.toFixed(round)} ± ${data.unc.toFixed(round)} ${unit}`;
    }
    return `${data.val.toFixed(round)} ${unit}`;
  }
  return 'NA';
}

export { treatData };
