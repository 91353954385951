import { Outlet } from 'react-router-dom';

export function HomeLayout({ navbar, children = <Outlet /> }) {
  return (
    <div className="w-full flex-auto xl:grid xl:grid-cols-2">
      <nav className="h-full w-full bg-slate-800">{navbar}</nav>
      <main className="h-full w-full">{children}</main>
    </div>
  );
}
