export function toStringCASRN(casrn) {
  if (casrn != undefined) {
    const strCASRN = casrn.toString();
    const strLength = strCASRN.length;
    return `${strCASRN.substring(0, strLength - 3)}-${strCASRN.substring(strLength - 3, strLength - 1)}-${strCASRN[strLength - 1]}`;
  }
}

export function toIntCASRN(casrn) {
  if (casrn != undefined) {
    return casrn.replace(/-/g, '');
  }
}

export default function ConvertCASRN(casrn) {
  const strCASRN = casrn.toString();
  const strLength = strCASRN.length;

  return `${strCASRN.substring(0, strLength - 3)}-${strCASRN.substring(strLength - 3, strLength - 1)}-${strCASRN[strLength - 1]}`;
}
