import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Show from 'components/Show';
import Spinner from 'components/Spinner';

const AsyncPage = forwardRef(({ query, children, className, loadingClassName, errorClassName }, ref) => {
  return (
    <div className={className} ref={ref}>
      <Show
        show={query.isLoading}
        className={twMerge('flex h-full w-full items-center justify-center', loadingClassName)}
      >
        <Spinner className="w-1/6" />
      </Show>
      <Show
        show={query.isError}
        as="div"
        className={twMerge('flex h-full flex-col items-center justify-center space-y-4', errorClassName)}
      >
        <div className="text-xl font-bold text-gray-500">에러가 발생했습니다.</div>
        <div className="text-xl font-bold text-gray-500"> 새로고침 혹은 뒤로가기를 해주십시요.</div>
      </Show>
      <Show show={!query.isLoading && !query.isError}>{children}</Show>
    </div>
  );
});

export default AsyncPage;
