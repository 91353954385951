import { toast } from 'react-toastify';

import { httpErrorList } from 'utils/http-errors';

function findDefinedError(errorCode) {
  const error = httpErrorList.find(e => e.code === errorCode);

  return (
    error ?? {
      code: 'unknown',
      message: '알 수 없는 오류입니다.',
      toast: true,
      alert: false,
      instruct: undefined,
    }
  );
}

function queryErrorHandler(query) {
  toast.error(query.error.message);
}

export { queryErrorHandler };
