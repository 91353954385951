import axios from 'axios';

/**
 * @typedef apiObject
 * @property {string} url
 * @property {string} method GET, POST, PUT, DELETE, PATCH
 * @property {object} data
 * @property {object} params
 */

/**
 * Axios를 이용하여 AJAX 요청 전송
 * @param {apiObject} object api object
 * @param {boolean} toMDL api.mdlkdb.com인지 여부
 * @return {object} api 응답
 */
async function request(object, toMDL = true) {
  const baseUrl = toMDL ? 'https://api.mdlkdb.com' : '';
  const url = baseUrl + object.url;
  const axiosReqeust = await axios({ url, method: object.method, data: object.data, params: object.params });

  return axiosReqeust;
}

export default request;
