import { useQuery } from '@tanstack/react-query';

import request from 'utils/http-request';
import NameToSMILES from 'hooks/name-smiles';
import change_SMILES from 'utils/encode_smiles';
import { queryErrorHandler } from 'utils/error-handler';

/**
 *
 * @param {object} queryObject
 * @returns
 */
function useCustomQuery(queryObject) {
  const query = useQuery(queryObject);

  if (query.isError) {
    queryErrorHandler(query);
  }

  // if (query.isSuccess) {
  //   console.log(query);
  // }

  return query;
}

//###########################//
//     React-Query-Hooks     //
//###########################//

function useSearch(querySearch, querySearchType) {
  const query = useCustomQuery({
    queryKey: ['search_pure_compound', querySearch, querySearchType],
    queryFn: async () => {
      let componentSearch = querySearch;
      if (querySearchType === 'name') {
        componentSearch = await NameToSMILES(querySearch);
        componentSearch = componentSearch.data.PropertyTable.Properties[0]['CanonicalSMILES'];
      }

      return await request({
        url: '/pure/search',
        method: 'GET',
        params: { search: change_SMILES(componentSearch) },
      });
    },
  });

  return query;
}

function useIdSearch(idSearch) {
  const query = useCustomQuery({
    queryKey: ['search_pure_compound_id', idSearch],
    queryFn: async () => {
      return await request({
        url: `/pure/search/${idSearch}`,
        method: 'GET',
      });
    },
  });

  return query;
}

function useEstimatePureConstants(compound, querySearchType) {
  const query = useCustomQuery({
    queryKey: ['estimate_pure_constants', compound, querySearchType],
    queryFn: async () => {
      let componentSearch = compound;
      console.log(compound);
      if (querySearchType === 'name') {
        componentSearch = await NameToSMILES(compound);
        componentSearch = componentSearch.data.PropertyTable.Properties[0]['CanonicalSMILES'];
      }
      return await request({
        url: '/pure/estimate',
        method: 'GET',
        params: { compound: change_SMILES(componentSearch) },
      });
    },
  });

  return query;
}

function useEstimateBinaryVLE(compound1, compound2, temperature, querySearchType) {
  const query = useCustomQuery({
    queryKey: ['estimate_binary_VLE', compound1, compound2, temperature, querySearchType],
    queryFn: async () => {
      let componentSearch1 = compound1;
      let componentSearch2 = compound2;
      if (querySearchType === 'name') {
        componentSearch1 = await NameToSMILES(compound1);
        componentSearch1 = componentSearch1.data.PropertyTable.Properties[0]['CanonicalSMILES'];
        componentSearch2 = await NameToSMILES(compound2);
        componentSearch2 = componentSearch2.data.PropertyTable.Properties[0]['CanonicalSMILES'];
      }
      return await request({
        url: '/binary-vle/estimate',
        method: 'GET',
        params: {
          compound1: change_SMILES(componentSearch1),
          compound2: change_SMILES(componentSearch2),
          temperature: temperature,
        },
      });
    },
  });

  return query;
}

// >>> 예제
// const mutationFn = async () => {};
// const mutate = useMutation({ mutationFn });
// return (
//   <button
//     onClick={() => {
//       mutate.mutate(data);
//     }}
//   />
// );
// <<<

export { useSearch, useIdSearch, useEstimatePureConstants, useEstimateBinaryVLE };
