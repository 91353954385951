import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import AsyncPage from 'components/AsyncPage';
import { useEstimateBinaryVLE } from 'hooks/query-hooks';
import { roundToSignificantFigures } from 'utils/data-treatment';

export default function BinaryVLEEstimationResult() {
  const [searchParams] = useSearchParams();
  const compound1 = searchParams.get('compound1');
  const compound2 = searchParams.get('compound2');
  const querySearchType = searchParams.get('type');
  const temperature = searchParams.get('temperature');
  const [VLEdata, setVLEdata] = useState();

  const vleResult = useEstimateBinaryVLE(compound1, compound2, temperature, querySearchType);

  useEffect(() => {
    if (vleResult.isSuccess) {
      const tempData = vleResult.data.data.x1.map((xValue, index) => ({
        x1: roundToSignificantFigures(xValue, 2),
        y1: vleResult.data.data.y1[index],
        P: roundToSignificantFigures(vleResult.data.data.Ps[index], 10),
      }));

      setVLEdata(tempData);
    }
  }, [vleResult.isSuccess]);

  return (
    <AsyncPage query={vleResult} className="content">
      <div className="my-8 text-center text-3xl font-bold">Isotherm VLE (atm)</div>
      <div className="mx-auto h-[30rem] w-full max-w-3xl">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={VLEdata} layout="vertical">
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis type="number" domain={[0, 1]} />
            <YAxis dataKey={'P'} type="number" reversed={true} domain={['auto', 'auto']} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="x1" stroke="#8884d8" name="Liquid Line (x1)" dot={false} />
            <Line type="monotone" dataKey="y1" stroke="#82ca9d" name="Vapor Line (y1)" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 text-center text-xl">component1 : {compound1}</div>
      <div className="text-center text-xl">component2 : {compound2}</div>
      <div className="text-center text-xl">temperature : {temperature} K</div>
    </AsyncPage>
  );
}
