// Modules
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// Layouts
import { MainApp } from 'layouts/MainApp';
import { HomeLayout } from 'layouts/HomeLayout';

// Routes
import ErrorPage from 'routes/error';
import PageNotFound from 'routes/404';
import Home from 'routes/home';
import Navbar from 'routes/home/navbar';
import PureConstantSearchCompounds from 'routes/search/pure-constant';
import PureConstantSearchResult from 'routes/search/pure-constant/_id';
import PureConstantEstimationResult from 'routes/estimation/pure-constant';
import BinaryVLEEstimationResult from 'routes/estimation/binary-VLE';
import BinaryVLESearchResult from 'routes/search/binary-VLE';

export default function Router() {
  return (
    // <ErrorBoundary fallback={<ErrorPage />}>
    <Routes>
      <Route element={<MainApp />}>
        <Route element={<HomeLayout navbar={<Navbar />} />}>
          <Route path="/" element={<Home />} />
          <Route path="search">
            <Route path="pure-constant" element={<PureConstantSearchCompounds />} />
            <Route path="pure-constant/:id" element={<PureConstantSearchResult />} />
            <Route path="binary-VLE" element={<BinaryVLESearchResult />} />
          </Route>
          <Route path="estimation">
            <Route path="pure-constant" element={<PureConstantEstimationResult />} />
            <Route path="binary-VLE" element={<BinaryVLEEstimationResult />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
    // </ErrorBoundary>
  );
}
