export default function Home() {
  return (
    <div className="content">
      <h1 className="text-xl: mb-2 mt-6 font-bold text-red-500">임시로 적은 형식입니다</h1>
      <p className="text-red-500">차후 KDB에 대한 설명이 적힐 예정입니다</p>
      <h1 className="text-xl: mb-2 mt-6 font-bold text-red-500">These are temporary examples.</h1>
      <h1 className="text-xl: mb-2 mt-6 font-bold">Contents</h1>
      <div>
        <li>Periodic Table of Elements</li>
        <li>Unit Conversion</li>
        <li>Universal Constants</li>
        <li>Pure Component Properties</li>
        <li>Binary Vapor-Liquid Equilibrium Data</li>
        <li>Calculation Modules</li>
      </div>
      <h1 className="text-xl: mb-2 mt-6 font-bold">KDB page contains</h1>
      <div>
        <li>Themophysical properties and equilbrium data for 2000 hydrocarbons and light gases.</li>
        <li>Themophysical properties and equilbrium data for 200 polymers and solvents.</li>
        <li>Themophysical properties and equilbrium data for 2000 electrolyte solutions.</li>
      </div>
      {/* <h1 className="text-xl: mb-2 mt-6 font-bold">Four laboratories in Korea collaborated to develop the database</h1>
      <div>
        <li>Molecular Design LAB , Korea University (Prof. Jeong Won Kang)</li>
        <li>Thermodynamics and Green Processing LAB., Sogang University (Prof. Ki-Pung Yoo)</li>
        <li>Thermophysical properties LAB, Seoul National University (Prof. Hwayong Kim)</li>
        <li>Energy and Environmental System LAB, KAIST (Prof. Heoun Lee)</li>
      </div> */}
      <h1 className="text-xl: mb-2 mt-6 font-bold">KDB project is supported by</h1>
      <div>
        <li>Ministry of Trade, Industry and Energy, Republic of Korea (MOTIE)</li>
        <li>Chemical Engineering & Material Research Infomation Center(CHERIC)</li>
      </div>
      <h1 className="text-xl: mb-2 mt-6 font-bold">Research Articles Citing KDB</h1>
      <div>
        <li>Journal Articles</li>
        <li>Thesis or dissertation</li>
        <li>Book</li>
      </div>
    </div>
  );
}
