import { twMerge } from 'tailwind-merge';

export default function BaseButton(props) {
  const { buttonClassName, containerClassName, register, type, children, ...otherProps } = props;

  return (
    <div className={twMerge('w-fit', containerClassName)}>
      <button className={buttonClassName} type={type} {...otherProps}>
        {children}
      </button>
    </div>
  );
}
